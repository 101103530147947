import { TranslationsSchema } from './types';

export default {
  name: 'approvalsInvoices',
  title: 'Approvals Invoice Overview',
  sanityId: 'approvalsInvoices',
  fields: {
    page_title: 'Invoice Approvals Overview',
    rfi_gr_items_not_included: 'Invoice approvals do not include RFI and GR items.',
    no_data: 'no data',
    task: 'Task',
    locked_by: 'Locked by',
    supplier_id: 'Supplier ID',
    overdue: 'OVERDUE',
    amount: 'Gross Amount',
    invoice_number: 'Invoice Number',
    invoice_date: 'Invoice Date',
    company_code: 'Company Code',
  },
} satisfies TranslationsSchema;
