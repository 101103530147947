/**
 * Fields that are used across the whole app.
 */
import { TranslationsSchema } from './types';

export default {
  name: 'globalFields',
  title: 'Global Fields',
  sanityId: 'globalFields',
  fields: {
    split_accounting: 'Split Accounting',
    split_accounts_no: 'No',
    split_accounts_yes: 'Yes',
    view_in_ariba: 'View in Ariba',
    view_in_IVT: 'View in IVT',
  },
} satisfies TranslationsSchema;
