import { TranslationsSchema } from './types';

export default {
  name: 'approvalsPrOverview',
  title: 'Approvals PR Overview',
  sanityId: 'approvalsPrOverview',
  fields: {
    page_title: 'PURCHASE REQUISITIONS APPROVALS OVERVIEW',
    task_approval: 'Task: Approval',
    supplier: 'Supplier',
    supplier_id: 'Supplier ID',
    //view_complete_list: 'View complete list of suppliers in Ariba.', // will come in handy when there are multiple suppliers
    group_approvals_Ariba:
      'Group Approvals that need PR re-assignment from the queue can be completed only in Ariba.',
    complete_action_Ariba: 'Please complete this action in Ariba if this applies to you.',
    no_data: 'no data',
    details_amount: 'Amount',
    details_number_of_lines: 'Number of Lines',
    details_date_assigned: 'Date Assigned',
    details_PR_requester: 'PR Requester',

    pending_plate_status: 'Pending',
    pending_plate_message:
      'PR Approval has been created. However, please allow a few minutes for it to be reflected in your PR approvals list.',
  },
} satisfies TranslationsSchema;
