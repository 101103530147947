import { TranslationsSchema } from './types';

export default {
  name: 'statusInvoiceDetail',
  title: 'Status Invoice Detail',
  sanityId: 'statusInvoiceDetail',
  fields: {
    unknown: 'Unknown',
    value: 'Value',
    invoice: 'Invoice',
    account_lines_title:
      'Account lines without Account, Cost Center, Profit Center and PO Number can be seen only in IVT for all task types',
    invoice_id: 'Invoice ID',
    invoice_number: 'Invoice Number',
    invoice_date: 'Invoice Date',
    invoice_status: 'Invoice Status',
    net_amount: 'Net Amount',
    tax_amount: 'Tax Amount',
    status_definitions: 'Status Definitions',
    amount: 'Amount',
    company_code: 'Company Code',
    payment_status: 'Payment Status',
    view_in_IVT: 'View in Invoicetrack',

    invoice_line_items: 'Invoice Line Items',
    invoice_line: 'Invoice Line',
    invoice_item: 'Invoice Item',
    line: 'Line',
    po_number: 'PO Number',
    order_id: 'Order ID:',
    payment_information: 'Payment Information',
    payment_term: 'Payment Term',
    payment_block: 'Payment Block',
    line_amount_invoiced: 'Line Amount Invoiced',
    po_line_amount: 'PO Line Amount',
    payment_date: 'Payment Date',
    total_invoice: 'Total Invoice Less Tax Currency Code',
    other_details: 'Other Details',
    supplier_id: 'Supplier ID',
    line_type: 'Line Type',
    task_creation_date: 'Task Creation Date',
    preparer: 'Preparer',
    requester: 'Requester',
    supplier: 'Supplier',
    view_in_invoicetrack: 'View in Invoicetrack',
    last_activity: 'Last Activity',
    view_last_activity: 'View Last Activity',
    view_last_activities_on_this_invoice: 'View the last activity on this invoice.',
    activity_name: 'Activity Name',
    creation_date: 'Creation Date',
    action: 'Action',
    username: 'Username',
    completed_date: 'Completed Date',
    modified_date: 'Modified Date',
    note: 'Note',
  },
} satisfies TranslationsSchema;
